import { legacyInstance } from './config/legacy'

class UserService {
  constructor () {
    this.url = '/api/v2/users'
  }

  me () {
    return legacyInstance.get(`${this.url}/profile`)
  }

  forgotPassword (email, captcha) {
    return legacyInstance.post(`${this.url}/forgot-password`, { email, captcha })
  }

  resetPassword (email, password, repeatPassword, token) {
    return legacyInstance.post(
      `${this.url}/reset-password`, {
        email,
        password,
        repeatPassword,
        token
      }
    )
  }

  getUser (id) {
    return legacyInstance.get(`${this.url}/${id}`)
  }

  getUsers (query) {
    return legacyInstance.get(`${this.url}`, {
      params: query
    })
  }

  getUsersWithInvitations (query) {
    return legacyInstance.get(`${this.url}/users-with-invitations`, {
      params: query
    })
  }

  updateMetadata (data) {
    return legacyInstance.post(`${this.url}/metadata`, data)
  }

  updateProfile (data) {
    return legacyInstance.put(`${this.url}/profile`, data)
  }

  changePassword (data) {
    return legacyInstance.put(`${this.url}/password`, data)
  }
}

export const userService = new UserService()
