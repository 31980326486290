// ** Redux Imports
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import { authReducer } from '../auth/auth.reducer'
import { userReducer } from '../user/user.reducer'
import { uiReducer } from '../ui/ui.reducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'authReducer',
    'userReducer',
    'uiReducer'
  ]
}

const rootReducer = combineReducers({
  authReducer,
  userReducer,
  uiReducer,
  navbar,
  layout
})

export default persistReducer(persistConfig, rootReducer)
