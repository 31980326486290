// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import Swal from 'sweetalert2'
import { authService } from '../../services/auth.service'
import { invitationService } from '../../services/invitation.service'
import { userService } from '../../services/user.service'
import { authTypes } from './auth.types'

export const startLogin = (email, password) => {
  return async dispatch => {
    try {
      dispatch({ type: authTypes.authLoginStart })
      const { data } = await authService.login(email, password)
      const { accessToken, refreshToken } = data
      const user = jwt_decode(accessToken)
      const authData = {
        user,
        accessToken,
        refreshToken
      }
      authService.setAuthData(authData)
      dispatch(loginSuccess(authData))
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Ocurrio un error al autenticarse, verifique sus credenciales.',
        type: 'error',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      })
      dispatch(loginFailure(error))
    }
  }
}

const loginSuccess = (data) => ({
  type: authTypes.authLoginSuccess,
  payload: data
})

const loginFailure = (error) => ({
  type: authTypes.authLoginFailure,
  payload: { error }
})

export const startLogout = () => dispatch => {
  authService.logout()
  dispatch(logout())
}

const logout = () => ({
  type: authTypes.authLogout
})

export const refreshTokenSuccess = ({ accessToken, refreshToken }) => ({
  type: authTypes.authRefreshToken,
  payload: {
    accessToken,
    refreshToken
  }
})

const refreshTokenFailure = () => ({
  type: authTypes.authRefreshTokenExpired
})

export const startRefreshTokenExpiry = () => dispatch => {
  Swal.fire({
    icon: 'error',
    title: 'Error!',
    text: 'La sesión ha expirado, ingrese nuevamente sus credenciales.',
    type: 'error',
    confirmButtonText: 'OK',
    customClass: {
      confirmButton: 'btn btn-primary'
    }
  })
  authService.logout()
  dispatch(refreshTokenFailure())
}

export const startAcceptInvitation = (invitationId, invitationToken) => {
  return async dispatch => {
    try {
      const { data } = await invitationService.accept(invitationId, invitationToken)
      dispatch(acceptInvitationSuccess(data))
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Ocurrio un error al aceptar la invitación',
        type: 'error',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      })
      dispatch(acceptInvitationFailure(error))
    }
  }
}

const acceptInvitationSuccess = ({ email, recoverToken, name }) => ({
  type: authTypes.acceptInvitationSuccess,
  payload: {
    email,
    recoverToken,
    name
  }
})

const acceptInvitationFailure = (error) => ({
  type: authTypes.acceptInvitationFailure,
  payload: { error }
})

export const startResetPassword = (email, password, password2, token) => {
  return async dispatch => {
    try {
      dispatch({ type: authTypes.authResetPasswordStart })
      const { data } = await userService.resetPassword(email, password, password2, token)
      if (data._id) {
        Swal.fire({
          icon: 'success',
          title: 'Contraseña cambiada!',
          text: 'Inicia sesión con tu nueva contraseña',
          type: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
        dispatch(resetPasswordSuccess())
      } else {
        throw new Error('Error al cambiar contraseña.')
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Ocurrio un error al cambiar el password, vuelva a intentar.',
        type: 'error',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      })
      dispatch(resetPasswordFailure(error))
    }
  }
}

const resetPasswordSuccess = () => ({
  type: authTypes.authResetPasswordSuccess
})

const resetPasswordFailure = (error) => ({
  type: authTypes.authResetPasswordFailure,
  payload: { error }
})
