import { legacyInstance } from './config/legacy'

class AuthService {
  login (email, password) {
    return legacyInstance.post(
      '/api/v2/auth/local', {
        email,
        password
      }
    )
  }

  logout () {
    localStorage.removeItem('authData')
  }

  getLocalAccessToken () {
    const authData = JSON.parse(localStorage.getItem('authData'))
    return authData?.accessToken
  }

  getLocalRefreshToken () {
    const authData = JSON.parse(localStorage.getItem('authData'))
    return authData?.refreshToken
  }

  updateLocalAccessToken ({ accessToken, refreshToken }) {
    const authData = JSON.parse(localStorage.getItem('authData'))
    authData.accessToken = accessToken
    authData.refreshToken = refreshToken
    localStorage.setItem('authData', JSON.stringify(authData))
  }

  getAuthData () {
    return JSON.parse(localStorage.getItem('authData'))
  }

  setAuthData (authData) {
    localStorage.setItem('authData', JSON.stringify(authData))
  }
}

export const authService = new AuthService()
