import { legacyInstance } from './legacy'
import { apiInstance } from './api'
import { refreshTokenSuccess, startRefreshTokenExpiry } from '../../redux/auth/auth.actions'
import { authService } from '../auth.service'

const setup = (store) => {
  legacyInstance.interceptors.request.use(
    (config) => {
      const token = authService.getLocalAccessToken()
      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  apiInstance.interceptors.request.use(
    (config) => {
      const token = authService.getLocalAccessToken()
      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  const { dispatch } = store

  legacyInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config

      if (((originalConfig.url !== '/api/v2/auth/local') &&
          (originalConfig.url !== '/api/v2/auth/tokens/refresh')) &&
          (err.response)) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            const response = await legacyInstance.post('/api/v2/auth/tokens/refresh', {
              refreshToken: authService.getLocalRefreshToken()
            })
            const { accessToken, refreshToken } = response.data

            dispatch(refreshTokenSuccess({ accessToken, refreshToken }))
            authService.updateLocalAccessToken({ accessToken, refreshToken })

            return legacyInstance(originalConfig)
          } catch (_error) {
            dispatch(startRefreshTokenExpiry())
            return Promise.reject(_error)
          }
        }
      }
      return Promise.reject(err)
    }
  )

  apiInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config

      if (((originalConfig.url !== '/api/v2/auth/local') &&
          (originalConfig.url !== '/api/v2/auth/tokens/refresh')) &&
          (err.response)) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            const response = await legacyInstance.post('/api/v2/auth/tokens/refresh', {
              refreshToken: authService.getLocalRefreshToken()
            })
            const { accessToken, refreshToken } = response.data

            dispatch(refreshTokenSuccess({ accessToken, refreshToken }))
            authService.updateLocalAccessToken({ accessToken, refreshToken })

            return apiInstance(originalConfig)
          } catch (_error) {
            dispatch(startRefreshTokenExpiry())
            return Promise.reject(_error)
          }
        }
      }
      return Promise.reject(err)
    }
  )
}

export default setup
