import { userTypes } from './user.types'

const initialState = {
  me: null,
  loading: false,
  error: null
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.getProfileStart:
      return {
        ...initialState,
        loading: true
      }

    case userTypes.getProfileSuccess:
      return {
        ...initialState,
        me: action.payload
      }

    case userTypes.getProfileFailure:
      return {
        ...initialState,
        error: action.payload
      }

    case userTypes.updateOnboardingStart:
      return {
        ...state,
        loading: true
      }

    case userTypes.updateOnboardingSuccess:
      return {
        ...state,
        loading: false,
        me: {
          ...state.me,
          onboardingStatus: action.payload
        }
      }

    case userTypes.updateOnboardingFailure:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    
    case userTypes.updateProfileData:
      return {
        ...state,
        loading: false,
        error: null,
        me: {
          ...state.me,
          ...action.payload
        }
      }

    default:
      return state
  }
}
