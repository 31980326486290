export const authTypes = {
  authLoginStart: '[Auth] login start',
  authLoginSuccess: '[Auth] login success',
  authLoginFailure: '[Auth] login failure',
  authLogout: '[Auth] logout',
  authRefreshToken: '[Auth] refresh token',
  authRefreshTokenExpired: '[Auth] refresh token expired',

  authResetPasswordStart: '[Auth] reset password start',
  authResetPasswordSuccess: '[Auth] reset password success',
  authResetPasswordFailure: '[Auth] reset password failure',

  acceptInvitationStart: '[Auth] accept invitation start',
  acceptInvitationSuccess: '[Auth] accept invitation success',
  acceptInvitationFailure: '[Auth] accept invitation failure'
}
