export const userTypes = {
  getProfileStart: '[User] get profile start',
  getProfileSuccess: '[User] get profile success',
  getProfileFailure: '[User] get profile failure',

  updateOnboardingStart: '[Auth] update onboarding start',
  updateOnboardingSuccess: '[Auth] update onboarding success',
  updateOnboardingFailure: '[Auth] update onboarding failure',
  
  updateProfileData: '[User] update profile data'
}
