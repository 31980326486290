import { uiTypes } from './ui.types'

const FAVORITE_DEFAULT_OPTIONS = [
  {
    id: 'intelligence-dashboards',
    path: '/intelligence/dashboards',
    icon: 'BarChart',
    label: 'Ver Reportes',
    module: 'intelligence',
    color: 'primary',
    img: 'https://yom-enterprise-images.s3.amazonaws.com/img-reportes.svg',
    selected: false
  },
  {
    id: 'intelligence-goals',
    path: '/intelligence/goals',
    icon: 'AutoGraph',
    label: 'Crear meta',
    module: 'intelligence',
    color: 'primary',
    img: 'https://yom-enterprise-images.s3.amazonaws.com/img-metas.svg',
    selected: true
  },
  {
    id: 'app-movil',
    path: '/app-movil',
    icon: 'Aod',
    label: 'App Móvil',
    module: 'app-movil',
    color: 'success',
    img: 'https://yom-enterprise-images.s3.amazonaws.com/img-tutoriales.svg',
    selected: true
  }
]

const initialState = {
  selectedModule: '',
  filterFavorites: [...FAVORITE_DEFAULT_OPTIONS],
  favorites: [...FAVORITE_DEFAULT_OPTIONS]
}

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case uiTypes.setSelectedModule:
      return {
        ...state,
        selectedModule: action.payload
      }

    case uiTypes.addFavorite:
      return {
        ...state,
        favorites: state.favorites.map((f) => {
          if (f.id === action.payload) {
            return { ...f, selected: true }
          } else {
            return f
          }
        }),
        filterFavorites: state.filterFavorites.map((f) => {
          if (f.id === action.payload) {
            return { ...f, selected: true }
          } else {
            return f
          }
        })
      }

    case uiTypes.deleteFavorite:
      return {
        ...state,
        favorites: state.favorites.map((f) => {
          if (f.id === action.payload) {
            return { ...f, selected: false }
          } else {
            return f
          }
        }),
        filterFavorites: state.filterFavorites.map((f) => {
          if (f.id === action.payload) {
            return { ...f, selected: false }
          } else {
            return f
          }
        })
      }

    case uiTypes.searchFavorite:
      return {
        ...state,
        filterFavorites: action.payload === '' ? state.favorites : state.favorites.filter((fav) => {
          return fav.label.toLowerCase().includes(action.payload.toLowerCase())
        })
      }

    default:
      return state
  }
}
