import { legacyInstance } from './config/legacy'

class InvitationService {
  constructor () {
    this.url = '/api/v2/invitations'
  }

  accept (invitationId, invitationToken) {
    return legacyInstance.post(`${this.url}/accept`, {
      invitationId, invitationToken
    })
  }

  invite (entity, data) {
    return legacyInstance.post(`${this.url}/${entity}`, data)
  }

  inviteCommerce (commerceId) {
    return legacyInstance.post(`${this.url}/commerce`, {
      commerceId
    })
  }

  getInvitation (id) {
    return legacyInstance.get(`${this.url}/${id}`)
  }
}

export const invitationService = new InvitationService()
