import { authTypes } from './auth.types'

const initialState = {
  accessToken: '',
  refreshToken: '',
  user: null,
  loading: false,
  error: null,
  invitationData: {
    email: '',
    name: '',
    recoverToken: ''
  },
  hasNewPassword: false
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.authResetPasswordStart:
      return {
        ...initialState,
        loading: true
      }

    case authTypes.authLoginStart:
      return {
        ...initialState,
        loading: true
      }

    case authTypes.acceptInvitationStart:
      return {
        ...initialState,
        loading: true
      }

    case authTypes.authResetPasswordSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        user: null,
        hasNewPassword: true
      }

    case authTypes.authLoginSuccess:
      return {
        ...initialState,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      }

    case authTypes.acceptInvitationSuccess:
      return {
        ...initialState,
        loading: false,
        invitationData: {
          email: action.payload.email,
          name: action.payload.name,
          recoverToken: action.payload.recoverToken
        }
      }

    case authTypes.authLoginFailure:
      return {
        ...initialState,
        error: action.payload.error
      }

    case authTypes.authResetPasswordFailure:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }

    case authTypes.acceptInvitationFailure:
      return {
        ...initialState,
        error: action.payload.error
      }

    case authTypes.authLogout:
      return {
        ...initialState
      }

    case authTypes.authRefreshToken:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      }

    case authTypes.authRefreshTokenExpired:
      return {
        ...initialState
      }

    default:
      return state
  }
}
